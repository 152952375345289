<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>

            <template slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">STO Doc. Num.</vs-th>
                <vs-th sort-key="">Transaction Date</vs-th>
                <vs-th sort-key="">Delivery Date</vs-th>
                <vs-th sort-key="">ETA</vs-th>
                <vs-th sort-key="">Source Warehouse</vs-th>
                <vs-th sort-key="">Dest. Warehouse</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total QTY (UOM)</vs-th>
                <vs-th sort-key="" v-if="status === 'Complete'">Vehicle Status</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td v-if="status === 'New'">
                        <div class="vx-input-group flex">
                            <vs-button
                                size="small"
                                color="warning"
                                icon-pack="feather"
                                icon="icon-edit"
                                title="Edit"
                                style="margin-right: 5px"
                                @click="handleEdit(tr.id)"
                            />
                            <vs-button
                                size="small"
                                color="danger"
                                icon-pack="feather"
                                icon="icon-x"
                                title="Cancel"
                                style="margin-right: 5px"
                                @click="handleCancel(tr.id, 'new')"
                            />
                            <vs-button
                                size="small"
                                color="success"
                                icon-pack="feather"
                                icon="icon-check"
                                title="Request Approval"
                                @click="handleApprovalRequest(tr.id)"
                            />
                        </div>
                    </vs-td>

                    <vs-td v-if="status === 'Waiting'">
                        <div class="vx-input-group flex">
                            <vs-button
                                size="small"
                                color="warning"
                                icon-pack="feather"
                                icon="icon-eye"
                                v-if="status !== 'New'"
                                title="Detail"
                                style="margin-right: 5px"
                                @click="handleDetail(tr.id)"
                            />
                            <!-- <vs-button
                                size="small"
                                color="danger"
                                icon-pack="feather"
                                icon="icon-x-square"
                                title="Cancel" @click="handleCancel(tr.id, 'waiting')"
                            /> -->
                        </div>
                    </vs-td>

                    <vs-td v-if="status !== 'New' && status !== 'Waiting'">
                        <div class="vx-input-group flex">
                            <vs-button
                                size="small"
                                color="warning"
                                icon-pack="feather"
                                icon="icon-eye"
                                title="Detail"
                                style="margin-right: 5px"
                                @click="handleDetail(tr.id)"
                            />
                            <vs-button
                                v-if="status === 'Complete'"
                                size="small"
                                color="purple"
                                icon-pack="feather"
                                icon="icon-unlock"
                                title="Unlock"
                                :disabled="tr.is_locked === false"
                                @click="handleUnlockVehicleDriver(tr.id)"
                            />
                        </div>
                    </vs-td>

                    <vs-td>{{ tr.code }}</vs-td>
                    <vs-td>{{ tr.date }}</vs-td>
                    <vs-td>{{ tr.req_delivery_date }}</vs-td>
                    <vs-td>{{ tr.eta }}</vs-td>
                    <vs-td>
                        Code : {{ tr.src_warehouse_code }}
                        <br>
                        Name : {{ tr.src_warehouse_name }}
                    </vs-td>
                    <vs-td>
                        Code : {{ tr.dest_warehouse_code }}
                        <br>
                        Name : {{ tr.dest_warehouse_name }}
                    </vs-td>
                    <vs-td>{{ tr.total_sku }}</vs-td>
                    <vs-td>{{ tr.quantity_uom }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.is_locked ? 'Locked' : 'Unlocked' }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>

<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        sourceWarehouseId: {
            type: Number,
        },
        destWarehouseId: {
            type: Number,
        },
        transactionDate: {
            type: Date,
            default: null,
        },
        status: {
            type: String,
            default: "New",
        },
    },
    data() {
        return {
            deleteId: null,
            actionBtn: "",
            table: this.tableDefaultState(),
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;
            if (valStart > this.table.total) {
                valStart = 1;
            }

            if (this.table.total == 0) {
                valStart = 0;
            }

            let valEnd = this.table.length * this.table.page;
            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (this.table.totalSearch < this.table.total && this.table.search != "") {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        getData() {
            this.$vs.loading();
            this.$http.get(this.$store.state.outbound.simpleSto.baseUrlPath, {
                params: {
                    length: this.table.length,
                    page: this.table.page,
                    search: this.table.search,
                    order: this.table.order,
                    sort: this.table.sort,
                    source_warehouse_id: this.sourceWarehouseId,
                    dest_warehouse_id: this.destWarehouseId,
                    status: this.status,
                    transaction_date: (this.transactionDate) ? moment(this.transactionDate).format("YYYY-MM-DD") : null,
                },
            })
            .then((resp) => {
                if (resp.code == 200) {
                    this.table.total = resp.data.total_record;
                    this.table.totalPage = resp.data.total_page;
                    this.table.totalSearch = resp.data.total_record_search;
                    this.table.length = resp.data.total_record_per_page;
                    this.table.data = resp.data.records;
                    this.setStartEnd();
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                    this.$vs.dialog({
                        type: "alert",
                        acceptText: `OK`,
                        color: "danger",
                        title: `Something went wrong`,
                        text: resp.message,
                    });
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
                this.$vs.dialog({
                    type: "alert",
                    acceptText: `OK`,
                    color: "danger",
                    title: `Something went wrong`,
                    text: error,
                });
            });
        },
        handleCancel(id, action = "") {
            this.deleteId = id;
            this.actionBtn = action
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Please confirm to delete this data",
                accept: this.acceptCancel,
            });
        },
        acceptCancel() {
            this.$vs.loading();
            this.$http.post("/api/wms/v1/outbound/simple-sto/cancel-document/" + this.deleteId + "/" + this.actionBtn)
            .then((resp) => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.$vs.dialog({
                        type: "alert",
                        acceptText: `OK`,
                        color: "success",
                        title: `Processed`,
                        text: resp.message,
                    });
                } else {
                    this.$vs.dialog({
                        type: "alert",
                        acceptText: `OK`,
                        color: "danger",
                        title: `Error`,
                        text: resp.message,
                    });
                }
                this.getData();
            })
            .catch((error) => {
                this.$vs.loading.close();
                this.$vs.dialog({
                    type: "alert",
                    acceptText: `OK`,
                    color: "danger",
                    title: `Error`,
                    text: error,
                });
            });
        },
        handleApprovalRequest(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Please confirm to request approval this data",
                accept: this.actionApprovalRequest,
            });
        },
        actionApprovalRequest() {
            this.$vs.loading();
            this.$http.post("/api/wms/v1/approval/simple-sto/send-approval/" + this.deleteId)
            .then((resp) => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.$vs.dialog({
                        type: "alert",
                        acceptText: `OK`,
                        color: "success",
                        title: `Processed`,
                        text: resp.message,
                    });
                } else {
                    this.$vs.dialog({
                        type: "alert",
                        acceptText: `OK`,
                        color: "danger",
                        title: `Error`,
                        text: resp.message,
                    });
                }
                this.getData();
            })
            .catch(error => {
                console.log(error)
                this.$vs.dialog({
                    type: "alert",
                    acceptText: `OK`,
                    color: "danger",
                    title: `Error`,
                    text: error,
                });
            });
        },
        handleEdit(id) {
            this.$router.push({
                name: this.$store.state.outbound.simpleSto.baseRouterName + "-edit",
                params: { id: id },
            });
        },
        handleDetail(id) {
            this.$router.push({
                name: "outbound.simple-sto-detail",
                params: { id: id },
            });
        },
        handlerAqua(id) {
            this.$http.post("/api/wms/v1/outbound/simple-sto/mendapatkan-pengelihatan")
        },
        handleUnlockVehicleDriver(id) {
            this.deleteId = id
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to unlock this vehicle and driver?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.post("/api/wms/v1/outbound/simple-sto/unlock-driver/" + this.deleteId)
                    .then(resp => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                color: "success",
                                title: "Unlock vehicle and driver",
                                text: resp.message,
                            });
                            this.getData();
                        } else {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                color: "danger",
                                title: "Something went wrong",
                                text: resp.message,
                            });
                        }
                    })
                    .catch(error => {
                        this.$vs.loading.close();
                        this.$vs.dialog({
                            color: "danger",
                            title: "Something went wrong",
                            text: error,
                        });
                    });
                }
            })
        },
    },
    mounted() {},
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
};
</script>